import React from "react";
import PropTypes from "prop-types";
import { Box, Center, Container, Image, Title } from "@mantine/core";
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const PartnerPageTemplate = ({ data }) => {
  const { markdownRemark: { frontmatter: partner, html, id } } = data;

  return (
    <Layout>
    <Helmet titleTemplate="%s | Partner">
      <title>{`${partner.title}`}</title>
      <meta
        name="description"
        content={`${partner.title}`}
      />
    </Helmet>
    <Box mt={56}>
      <Container size="lg" py="xl">
        <Title
          order={1}
          color="rgba(50, 59, 60, 0.8)"
          ff="Oswald"
          ml="sm"
        >
          {partner.title}
        </Title>
        <Center>
          <Image src={partner.logoUrl} alt={partner.title} w="3rem" height="auto"/>
        </Center>
        <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Box>
    </Layout>
  );
}


PartnerPageTemplate.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
};

export default PartnerPageTemplate;

export const pageQuery = graphql`

query PartnerPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
        id
        html
        frontmatter {
            title
            website
            logoUrl
        }
    }
}
`




